var flyoutNav = true;
var stickyHeader = true;
var countUpStats = true;
var countUpStatsDuration = 3000;

// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// see https://fresco-docs.netlify.com/#/carousel/background-video
// ---------------------------------------------------------------
// var carouselBackgroundVideoYouTubeID = '';

// var visualAppealsLoadMore = false;
// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// -----------------------------------------------------------------
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link'];
// var feedItemsCarouselBreakpoint = 576;

// see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// --------------------------------------------------------------------
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Make sure to set a card layout prefab for:
// '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'
// --------------------------------------------------------------------------------
// var sidebarCards = false;

$(document).ready(function () {
  // Header -----------------
  // Replicate nav links
  replicateNavLinks();
  // Replicate main CTA buttons
  replicateMainCTAButtons();

  // Footer ------------------
  // Move the copyright block outside
  adjustNewsletter();
  adjustFooterSection();
  adjustFooterCopyright();

  // Homepage ---------------
  // quick adjust homepage carousel
  adjustHomepageCarousel();
  // home boxes adjustment
  adjustStarterContainer();
  adjustCampaignContainer();
  adjustSupportContainer();
  adjustFundraiseContainer();
  adjustDiscoverContainer();
  adjustQuickGiving();
  adjustImpactStats();
  // home feeds adjustment
  adjustFamilyVoices();
  adjustLatestNews();

  // Internal -------------
  // Move sub banner descriptions out to below with the content
  if ($(".listingIntro").length) {
    $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
    $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
  // Move sub banner title out if carousel multiple is used
  if ($(".carouselUsed").length) {
    $(".carouselUsed .headerText").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".carouselUsed .headerText").prependTo(".appealListingPage .donorsListWrapper");
    $(".carouselUsed .headerText").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
  // Check if the div is "empty" (ignoring whitespace) - hide if yes
  if ($(".headerText").children().length === 0 || $(".headerText").children().text().trim() === "") {
    $(".headerText").hide();
  }
  if ($(".listingIntro").children().length === 0 || $(".listingIntro").children().text().trim() === "") {
    $(".listingIntro").hide();
  }
  // Move share wrapper outside of post block content
  if ($(".shareWrapper").length) {
    $(".shareWrapper").insertBefore(
      ".contentBlockWrapper > .contentBlock .postFeedWrapper, .contentBlockWrapper > .contentBlock  .postAside"
    );
  }
  // Move comments outside of post content
  if ($(".postContent .postComments").length) {
    $(".postContent .postComments").insertBefore(".contentBlockWrapper > .contentBlock .postFeedWrapper");
  }
  // Move appeal widget outside of post content
  if ($(".appealInteractionsWrapper").length) {
    $(".appealInteractionsWrapper").insertBefore(".contentBlockWrapper > .contentBlock .postFeedWrapper");
  }
  // Move footer block for listing to inside summary block
  if ($('.listing').length) {
    var listedPost = $('.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem)');
    listedPost.each(function () {
      let post = $(this).find('[class^="listed"][class$="Text"]');
      let footer = $(this).find('.postFooterWrapper');
      footer.appendTo(post);
    });
  }

  // on the interactive map page
  if ($('body').hasClass('PostCategory_interactive-map')) {
    initInteractiveMap();
    $(window).resize(function () {
      initInteractiveMap();
    });
  };

});

// just in case - weirdly slick doesnt use responsive on load
// suspect it is due to the external jotform messing up execution
$(window).on("load", function () {
  $(".homeFeed .feedList").slick("refresh");
})

function adjustHomepageCarousel() {
  // reference elements
  const container = $("body.homepage #carouselSlides");
  // modify first slide button text
  container.find("li:first-of-type .carouselSlideReadMore").html("Make a donation");
}

function adjustImpactStats() {
  const container = $(".homefeaturecategory-homeboximpactstats");
  container.find("img").wrap('<div class="image-wrapper"></div>');
  container.wrapInner('<div class="stats-wrapper"></div>');
  container.find(".image-wrapper").insertBefore(container.find(".stats-wrapper"));
}

function adjustQuickGiving() {
  const banner = $(".homefeaturecategory-donatebanner");
  const panel = $(".homefeaturecategory-donatepanel");
  banner.wrapInner('<div class="image-wrapper"></div>');
  panel.wrapInner('<div class="content-wrapper"></div>');
  banner.appendTo(panel);
}

function adjustDiscoverContainer() {
  const container = $(".homefeaturecategory-discovercontainer");
  container.wrapInner('<div class="content-wrapper"></div>');
  container.wrapInner('<div class="cta-boxed"></div>');
  container.find("ul").addClass("cta-wrapper");
  container.find(".cta-wrapper").appendTo(container.find(".cta-boxed"));
  container.find(".content-wrapper").insertBefore(container.find(".cta-boxed"));
}

// Adjust Starter Container
function adjustStarterContainer() {
  const container = $(".homefeaturecategory-startercontainer");
  container.wrapInner('<div class="content-wrapper"></div>');
  container.find("ul").addClass("cta-wrapper");
  container.find(".cta-wrapper").insertBefore(container.find(".content-wrapper"));
}

function adjustFundraiseContainer() {
  const container = $(".homefeaturecategory-fundraisecontainer");
  container.find("img").wrap('<div class="image-wrapper"></div>');
  container.wrapInner('<div class="content-wrapper"></div>');
  container.find(".image-wrapper").insertAfter(container.find(".content-wrapper"));
}

function adjustSupportContainer() {
  const container = $(".homefeaturecategory-supportcontainer");
  container.wrapInner('<div class="content-wrapper"></div>');
  container.find("ul").addClass("cta-wrapper");
  container.find(".cta-wrapper").insertBefore(container.find(".content-wrapper"));
}

function adjustCampaignContainer() {
  const container = $(".homefeaturecategory-campaigncontainer");
  container.find("img").wrap('<div class="image-wrapper"></div>');
  container.wrapInner('<div class="content-wrapper"></div>');
  container.find(".image-wrapper").insertAfter(container.find(".content-wrapper"));
}

function adjustFooterCopyright() {
  // Reference the footer parent
  const footerParent = $("#pageFooterWrapper");
  // Reference the copyright block
  const footerCopyright = $("#pageFooterWrapper .FooterCredits");
  // Move it outside the default wrapper
  footerCopyright.appendTo(footerParent);
}

function adjustFooterSection() {
  // Wrap footerBoxx6 and footerBox7 in a new section
  $(".FooterSocials, .FooterLogos").wrapAll('<div class="socials-logos-wrapper"></div>');
}

function adjustNewsletter() {
  // Create a new main section
  var $mainSection = $('<div class="footerBox mainNewsletter"></div>');
  // Append footerBox1 and footerBox2 into the new main section
  $(".NewsletterTagline, .NewsletterSign-up").appendTo($mainSection);
  // Append the main section to the pageFooterWrapper
  $mainSection.prependTo(".pageFooterWrapper");
}

function adjustLatestNews() {
  // initiate feed
  initCarousel(
    "homeFeedBox2",
    "See all news",
    "/Pages/News/",
    "Slide for more news", false, 2
  );
  // element ref
  const stories = $(".homefeaturecategory-ournewsfeed");
  const feedbox = $(".homeFeedBox2");
  // put in the divs
  stories.wrapInner('<div class="cta-wrapper"></div>');
  // move all the stuff
  feedbox.prependTo(stories);
}

function adjustFamilyVoices() {
  // initiate feed
  initCarousel(
    "homeFeedBox1",
    "See all stories",
    "/Listing/Category/family-voices",
    "Slide for more stories", true, 1, false, true
  );
  // element ref
  const tagline = $(".homefeaturecategory-ourstoriestagline");
  const stories = $(".homefeaturecategory-ourstoriesfeed");
  const feedbox = $(".homeFeedBox1");
  // put all the divs
  tagline.wrapInner('<div class="tagline-wrapper"></div>');
  stories.wrapInner('<div class="cta-wrapper"></div>');
  // move all the stuff
  feedbox.appendTo(stories);
  stories.appendTo(tagline);
}

// Carousel functions
function initCarousel(feed, ctaText, ctaURL, slideText, optFade = false, optShow = 2, optAuto = false, optHeight = false) {
  // Set the carousel selector
  let carousel = ".homeFeed." + feed;
  // If carousl exists on page
  if ($(`${carousel}`).length) {
    // Adjust the feed details first
    adjustFeedDetails(carousel);
    // Create the slider arrows + dots + cta
    let elements = createSliderElements(ctaText, ctaURL, slideText);
    // Then append under the carousel
    $(`${carousel}`).append(elements.navigator);
    // Init slick slider
    $(`${carousel} .feedList`).slick({
      dots: true,
      arrows: true,
      appendDots: $(`${carousel} .dot-wrapper`),
      prevArrow: $(`${carousel} .prev-slide`),
      nextArrow: $(`${carousel} .next-slide`),
      fade: optFade,
      infinite: true,
      slidesToShow: optShow,
      slidesToScroll: 1,
      autoplay: optAuto,
      autoplaySpeed: 4000,
      adaptiveHeight: optHeight,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}

function adjustFeedDetails(carousel) {
  // for each feed item present
  $(`${carousel} .feedItem`).each(function () {
    // Set reference to both footer and details wrapper of feed
    let footerWrapper = $(this).find(".postFooterWrapper");
    let detailsWrapper = $(this).find(".feedItemDetailsWrapper");
    // Move the footer wrapper to the details wrapper
    footerWrapper.prependTo(detailsWrapper);
  });
}

function createSliderElements(ctaText, ctaURL, scrollText) {
  let iconPrev = '<i class="fa fa-chevron-left" aria-hidden="true"></i>';
  let iconNext = '<i class="fa fa-chevron-right" aria-hidden="true"></i>';
  let prevArrow = '<button type="button" aria-label="Previous slide" class="prev-slide">' + iconPrev + '</button>';
  let nextArrow = '<button type="button" aria-label="Next slide" class="next-slide">' + iconNext + '</button>';
  let dotCustom = '<div class="dot-wrapper"></div>';
  let sliderNav = '<div class="slider-nav">' + prevArrow + dotCustom + nextArrow + "</div>";
  let slideMore = '<p class="slider-label">' + scrollText + '</p>';
  let sliderBox = '<div class="slider-box">' + slideMore + sliderNav + "</div>";
  let ctaButton = '<a class="button" href="' + ctaURL + '" aria-label="' + ctaText + '">' + ctaText + "</a>";
  let navigator = '<div class="navigator">' + sliderBox + ctaButton + "</div>";
  return {
    prevArrow,
    nextArrow,
    dotCustom,
    sliderNav,
    slideMore,
    sliderBox,
    navigator,
  };
}

function replicateMainCTAButtons() {
  // Reference the burger menu, the fly off menu and main CTA buttons
  const menuMain = $("#menuMain");
  const burgerMenu = $("#menuMain .menuMainAlt");
  const headerLogo = $("#pageHeader .headerContent .mainLogo");
  const mainCallToActionButtons = $(
    "#pageHeader #menuMain .mainCallToActionButtons"
  );
  // Create a new parent element
  var interactMenu = $('<div id="interactMenu"></div>');
  var logoBurgerWrapper = $('<div class="logo-burger"></div>');
  // Clone site logo and move to the new wrapper element
  headerLogo.clone().appendTo(logoBurgerWrapper);
  // And also the burger menu
  burgerMenu.appendTo(logoBurgerWrapper);
  // Append the CTA buttons to the new parent element
  mainCallToActionButtons.appendTo(interactMenu);
  // Move to the first top level
  interactMenu.prependTo(menuMain);
  // And also the new wrapper
  logoBurgerWrapper.appendTo(interactMenu);
}

function replicateNavLinks() {
  // Reference the main call to action buttons on visible menu
  const mainCallToActionButtons = $(
    "#pageHeader .headerContent .mainCallToActionButtons"
  );
  // Select the first four list items
  var firstFourNavs = $("#menuMain .topLevel .level1").slice(0, 4);
  // Create a new parent element
  var replicaMenu = $('<div id="replicaMenu"></div>');
  // Clone them and append the clones to the new parent element
  firstFourNavs.clone().appendTo(replicaMenu);
  // Then move it after main call to action buttons
  replicaMenu.insertAfter(mainCallToActionButtons);
}

function initInteractiveMap() {
  if ($(window).width() > 991) {
    pinpointMapButtons();
    $(window).resize(function () {
      pinpointMapButtons()
    });
    $(".map-pin").on("mouseenter", function () {
      var countryId = "#" + $(this).data("id");
      $("#world_map .map.active").removeClass("active");
      $(`#world_map ${countryId}`).addClass("active");
    });
  }
}

// function to pinpoint buttons to the map
function pinpointMapButtons() {
  $("#map-buttons .map-pin").each(function () {
    var $this = $(this);
    var countryId = $this.data("id");
    var countryEl = document.querySelector(`#world_map #${countryId}`);
    var positions = absolutePosition(countryEl);
    switch (countryId) {
      case "Scotland":
        var offsetY = positions.top + (positions.height / 2) - 30;
        var offsetX = positions.left + (positions.width / 2) + 80;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      case "North":
        var offsetY = positions.top + 150;
        var offsetX = positions.left + 150;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      case "NI":
        var offsetY = positions.top + 30;
        var offsetX = positions.left + -60;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      case "Midlands":
        var offsetY = positions.top + (positions.height / 2) - 60;
        var offsetX = positions.left + (positions.height / 2) + 35;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      case "Wales":
        var offsetY = positions.top + (positions.height / 2) - 10;
        var offsetX = positions.left + (positions.height / 2) - 120;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      case "South":
        var offsetY = positions.top + 190;
        var offsetX = positions.left + 50;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      case "London":
        var offsetY = positions.top + 20;
        var offsetX = positions.left + 40;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
        break;
      default:
        var offsetY = positions.top;
        var offsetX = positions.left;
        $(`.map-pin[data-id="${countryId}"]`).css({ top: offsetY + "px", left: offsetX + "px", bottom: "unset" });
    }
  });
}

// function to get absolute positioning on the screen
function absolutePosition(el) {
  var
    found,
    left = 0,
    top = 0,
    width = 0,
    height = 0,
    offsetBase = absolutePosition.offsetBase;
  if (!offsetBase && document.body) {
    offsetBase = absolutePosition.offsetBase = document.createElement('div');
    offsetBase.style.cssText = 'position:absolute;left:0;top:0';
    document.body.appendChild(offsetBase);
  }
  if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
    var boundingRect = el.getBoundingClientRect();
    var baseRect = offsetBase.getBoundingClientRect();
    found = true;
    left = boundingRect.left - baseRect.left;
    top = boundingRect.top - baseRect.top;
    width = boundingRect.right - boundingRect.left;
    height = boundingRect.bottom - boundingRect.top;
  }
  return {
    found: found,
    left: left,
    top: top,
    width: width,
    height: height,
    right: left + width,
    bottom: top + height
  };
}
